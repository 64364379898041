import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGoogleMap,
  CVertCard,
  CDefinition,
  LContact,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESS',
            sub: 'お車でのアクセス・駐車場',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/car/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/car/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '当ホテルについて',
                path: '/about/',
              },
              {
                label: 'アクセス',
                path: '/about/access/',
              },
            ],
            current: {
              label: 'お車でのアクセス・駐車場',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <section className="u_mb25">
            <CGoogleMap />
          </section>
          <p>首都高速横羽線「みなとみらいランプ」より1分。</p>
          <ul className="c_noteList u_mbMedium">
            <li>みなとみらいランプは、上下線の出入口があります。</li>
          </ul>
          <h3 className="c_headingLv5">ホテル正⾯⽞関</h3>
          <p className="u_mb25">
            （上り）横浜駅東口方面
            <br />
            「みなとみらいランプ」を出て最初の信号を右折、2つ目の信号を左折すると、左手がホテル正面玄関です。
          </p>
          <p className="u_mb25">
            （下り）横浜公園方面
            <br />
            「みなとみらいランプ」を出て最初の信号を右折、次の信号を左折すると、左手がホテル正面玄関です。
          </p>
          <h3 className="c_headingLv5">ホテル宴会棟⼊⼝</h3>
          <p className="u_mbMedium">
            「横浜美術館」と「三菱重⼯横浜ビル」の間の道から「けやき通り」へ出ると、正⾯がホテル宴会棟⼊⼝です。
          </p>
          <h2 className="c_headingLv2 u_mb20">駐車場のご利用について</h2>
          <p className="u_mb15">
            ランドマークタワーのB1F~B3Fに1,400台分の駐車スペースをご用意しております。
            <br />
            ただし、ホテル専用スペースではございませんのでご了承ください。
            <br />
            車高制限は2.1mです。
            <br />
            詳細は、
            <Link
              to="https://www.yokohama-landmark.jp/parking/index.html"
              target="_blank"
              rel="noopner noreferrer"
            >
              横浜ランドマークタワー
            </Link>
            のサイトでご確認ください。
          </p>
          <ul className="c_noteList u_mb25">
            <li>
              駐車場はA、B、Cブロックに分かれており、ホテル棟に近いブロックはAブロック、宴会棟に近いブロックはBブロックです。
            </li>
            <li>
              土・日・祝日は駐車場および周辺道路の混雑が予想されますので、公共交通機関のご利用をおすすめいたします。
            </li>
            <li>
              ご予約は承っておりませんので、時間に余裕をもってお越しください。
            </li>
          </ul>
          <CVertCard
            data={[
              {
                img: {
                  src: '/assets/images/about/access/car/img_car.png',
                  alt: '',
                },
                title: 'ホテル棟側 駐車場入口',
              },
              {
                img: {
                  src: '/assets/images/about/access/car/img_car02.png',
                  alt: '',
                },
                title: '宴会棟側 駐車場入口',
              },
            ]}
          />
          <h3 className="c_headingLv5 u_tac u_mb30">駐車場料金</h3>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '通常料金',
                text: <>30分 280円</>,
              },
              {
                title: 'ご宿泊の場合',
                text: (
                  <>
                    24時間 1,600円／1台
                    <br />
                    駐車券をご持参の上、フロントでお申付けください。
                    <ul className="c_noteList">
                      <li>
                        お車をお停めいただいた時間から24時間駐車・出入庫可能です。
                      </li>
                      <li>
                        チェックイン前でもお車をお停めいただくことは可能です。
                      </li>
                      <li>
                        横浜ランドマークタワーの駐車場をご利用いただいた場合に限ります。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: (
                  <>
                    レストランご利用の
                    <br className="u_pc" />
                    場合
                  </>
                ),
                text: (
                  <>
                    5,000円以上のご利用で2時間サービスになります。
                    <br />
                    駐車券をご持参の上、ご利用レストランでお申付けください。
                  </>
                ),
              },
              {
                title: <>ウエディングの場合</>,
                text: (
                  <>
                    {/* <p className="u_mb30">
                      ■ご見学・ブライダルフェアの場合■
                      <br />
                      ご利用時間分は無料サービスとなります。
                      <br />
                      駐車券をご持参の上、ブライダルサロンでお申付けください。
                    </p> */}
                    <p className="u_mb30">
                      {/* ■ご列席の場合■
                      <br /> */}
                      ご披露宴時間分は無料サービスとなります。
                      <br />
                      駐車券をご持参の上、宴会クロークでお申付けください。
                    </p>
                  </>
                ),
              },
              {
                title: <>宴会・会議の場合</>,
                text: (
                  <>
                    ご宴会により無料サービスの時間が異なりますので、詳しくはセールス部にお問合せください。
                    <br />
                    セールス部　TEL <a href="tel:0452211000">045-221-1000</a>
                    （平日のみ）
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList">
            <li>
              上記優待時間を過ぎた場合は通常料金でのご負担になりますので、ご了承ください。
            </li>
            <li>
              上記優待は、横浜ランドマークタワー及びクイーンズスクエア横浜、MARK
              IS みなとみらいの駐車場をご利用いただいた場合に限ります。
            </li>
          </ul>
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
